import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Container, Row, Col, Carousel } from "react-bootstrap"
import imageMetadata from "./image-metadata.json"

const CarouselViewer = () => {
  const { carouselImages } = useStaticQuery(graphql`{
    carouselImages: allFile(
      filter: {relativeDirectory: {eq: "carouselImages"}}
      sort: {fields: name, order: ASC}
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
            )
            parent {
              ... on File {
                name
              }
            }
          }
        }
      }
    }
  }`)

  return (
    <Container fluid>
      <Row>
        <Col md={{ span: 10, offset: 1 }} xs={{ span: 10, offset: 2 }} sm={{ span: 9, offset: 2 }}>
          <Carousel className="mt-5">
            {carouselImages.edges.map(({ node: image }, index) => {
              const metadata = imageMetadata.metadata[index]

              return (
                <Carousel.Item key={index}>
                  <GatsbyImage
                    image={getImage(image)}
                    alt={metadata.altText}
                  />
                  <Carousel.Caption className="d-none d-sm-block">
                    <small>
                      Photo by{' '}
                      <a
                        className="text-white"
                        href={metadata.creditUrl}
                        target="_blank" rel="noopener noreferrer">
                        {metadata.credit}
                      </a>{' '}
                      <sup>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 32 32">
                          <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                          <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                        </svg>
                      </sup>
                      on{' '}
                      <a
                        className="text-white"
                        href="https://unsplash.com/?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
                        target="_blank" rel="noopener noreferrer">
                        Unsplash
                      </a>{' '}
                      <sup>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 32 32">
                          <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                          <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                        </svg>
                      </sup>
                    </small>
                  </Carousel.Caption>
                </Carousel.Item>
              )
            })}
          </Carousel>
        </Col>
      </Row>
    </Container>
  )
}

export default CarouselViewer