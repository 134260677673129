import React, { useEffect } from "react";

export default function InteractiveNodes() {

  useEffect(() => {
    (function () {
      var lastTime = 0;
      var vendors = ['ms', 'moz', 'webkit', 'o'];
      for (var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
        window.requestAnimationFrame = window[vendors[x] + 'RequestAnimationFrame'];
        window.cancelAnimationFrame = window[vendors[x] + 'CancelAnimationFrame']
          || window[vendors[x] + 'CancelRequestAnimationFrame'];
      }

      if (!window.requestAnimationFrame)
        window.requestAnimationFrame = function (callback, element) {
          var currTime = new Date().getTime();
          var timeToCall = Math.max(0, 16 - (currTime - lastTime));
          var id = window.setTimeout(function () { callback(currTime + timeToCall); },
            timeToCall);
          lastTime = currTime + timeToCall;
          return id;
        };

      if (!window.cancelAnimationFrame)
        window.cancelAnimationFrame = function (id) {
          clearTimeout(id);
        };
    }());

    var Nodes = {

      // Settings
      density: 16,

      drawDistance: 24,
      baseRadius: 4,
      maxLineThickness: 4,
      reactionSensitivity: 3,
      lineThickness: 1,

      points: [],
      mouse: { x: -1000, y: -1000, down: false },

      animation: null,

      canvas: null,
      context: null,

      imageInput: null,
      bgImage: null,
      bgCanvas: null,
      bgContext: null,
      bgContextPixelData: null,
    }

    function init() {
      // Set up the visual canvas 
      var canvas = document.getElementById('canvas');
      var context = canvas.getContext('2d');
      Nodes.canvas = canvas;
      Nodes.context = context;
      Nodes.context.globalCompositeOperation = "lighter";
      Nodes.canvas.width = window.innerWidth / 2;
      Nodes.canvas.height = Nodes.canvas.width;
      Nodes.canvas.style.display = 'block'

      Nodes.imageInput = document.createElement('input');
      Nodes.imageInput.setAttribute('type', 'file');
      Nodes.imageInput.style.visibility = 'hidden';
      Nodes.imageInput.addEventListener('change', Nodes.upload, false);
      document.body.appendChild(Nodes.imageInput);

      Nodes.canvas.addEventListener('mousemove', (e) => { mouseMove(e) }, false);
      Nodes.canvas.addEventListener('mousedown', (e) => { mouseDown(e) }, false);
      Nodes.canvas.addEventListener('mouseup', (e) => { mouseUp(e) }, false);
      Nodes.canvas.addEventListener('mouseout', (e) => { mouseOut(e) }, false);

      window.onresize = function (event) {
        Nodes.canvas.width = window.innerWidth / 2;
        Nodes.canvas.height = Nodes.canvas.width;
        onWindowResize();
      }

      loadData();
    }

    function preparePoints() {

      // Clear the current points
      Nodes.points = [];

      var i, j;

      var colors = Nodes.bgContextPixelData.data;

      for (i = 0; i < Nodes.canvas.height; i += Nodes.density) {

        for (j = 0; j < Nodes.canvas.width; j += Nodes.density) {

          var pixelPosition = (j + i * Nodes.bgContextPixelData.width) * 4;

          // Dont use whiteish pixels
          if ((colors[pixelPosition] > 200 && (colors[pixelPosition + 1]) > 200 && (colors[pixelPosition + 2]) > 200) || colors[pixelPosition + 3] === 0) {
            continue;
          }

          var color = `rgba(${colors[pixelPosition]},${colors[pixelPosition + 1]},${colors[pixelPosition + 2]},1)`;
          Nodes.points.push({ x: j, y: i, originalX: j, originalY: i, color: color });

        }
      }
    }

    function updatePoints() {

      var i, currentPoint, theta, distance;

      for (i = 0; i < Nodes.points.length; i++) {

        currentPoint = Nodes.points[i];

        theta = Math.atan2(currentPoint.y - Nodes.mouse.y, currentPoint.x - Nodes.mouse.x);

        if (Nodes.mouse.down) {
          distance = Nodes.reactionSensitivity * 200 / Math.sqrt((Nodes.mouse.x - currentPoint.x) * (Nodes.mouse.x - currentPoint.x) +
            (Nodes.mouse.y - currentPoint.y) * (Nodes.mouse.y - currentPoint.y));
        } else {
          distance = Nodes.reactionSensitivity * 100 / Math.sqrt((Nodes.mouse.x - currentPoint.x) * (Nodes.mouse.x - currentPoint.x) +
            (Nodes.mouse.y - currentPoint.y) * (Nodes.mouse.y - currentPoint.y));
        }


        currentPoint.x += Math.cos(theta) * distance + (currentPoint.originalX - currentPoint.x) * 0.05;
        currentPoint.y += Math.sin(theta) * distance + (currentPoint.originalY - currentPoint.y) * 0.05;

      }
    }

    function drawLines() {

      var i, j, currentPoint, otherPoint, distance;

      for (i = 0; i < Nodes.points.length; i++) {

        currentPoint = Nodes.points[i];

        // Draw the dot.
        Nodes.context.fillStyle = currentPoint.color;
        Nodes.context.strokeStyle = currentPoint.color;

        for (j = 0; j < Nodes.points.length; j++) {

          // Distaqnce between two points.
          otherPoint = Nodes.points[j];

          if (otherPoint === currentPoint) {
            continue;
          }

          distance = Math.sqrt((otherPoint.x - currentPoint.x) * (otherPoint.x - currentPoint.x) +
            (otherPoint.y - currentPoint.y) * (otherPoint.y - currentPoint.y));

          if (distance <= Nodes.drawDistance) {

            Nodes.context.lineWidth = (1 - (distance / Nodes.drawDistance)) * Nodes.maxLineThickness * Nodes.lineThickness;
            Nodes.context.beginPath();
            Nodes.context.moveTo(currentPoint.x, currentPoint.y);
            Nodes.context.lineTo(otherPoint.x, otherPoint.y);
            Nodes.context.stroke();
          }
        }
      }
    }

    function drawPoints() {

      var i, currentPoint;

      for (i = 0; i < Nodes.points.length; i++) {

        currentPoint = Nodes.points[i];

        // Draw the dot.
        Nodes.context.fillStyle = currentPoint.color;
        Nodes.context.strokeStyle = currentPoint.color;

        Nodes.context.beginPath();
        Nodes.context.arc(currentPoint.x, currentPoint.y, Nodes.baseRadius, 0, Math.PI * 2, true);
        Nodes.context.closePath();
        Nodes.context.fill();

      }
    }

    function draw() {
      Nodes.animation = requestAnimationFrame(function () { draw() });

      clear();
      updatePoints();
      drawLines();
      drawPoints();

    }

    function clear() {
      Nodes.canvas.width = window.innerWidth / 2;
    }

    // The filereader has loaded the image... add it to image object to be drawn
    function loadData() {
      Nodes.bgImage = new Image();
      Nodes.bgImage.src = "../../icon.png";
      Nodes.bgImage.onload = function () {
        //Nodes
        drawImageToBackground();
      }
    }

    // Image is loaded... draw to bg canvas
    function drawImageToBackground() {

      Nodes.bgCanvas = document.createElement('canvas');
      Nodes.bgCanvas.width = Nodes.canvas.width;
      Nodes.bgCanvas.height = Nodes.canvas.height;

      var newWidth, newHeight;

      // If the image is too big for the screen... scale it down.
      if (Nodes.bgImage.width > Nodes.bgCanvas.width - 100 || Nodes.bgImage.height > Nodes.bgCanvas.height - 100) {

        var maxRatio = Math.max(Nodes.bgImage.width / (Nodes.bgCanvas.width - 100), Nodes.bgImage.height / (Nodes.bgCanvas.height - 100));
        newWidth = Nodes.bgImage.width / maxRatio;
        newHeight = Nodes.bgImage.height / maxRatio;

      } else {
        newWidth = Nodes.bgImage.width;
        newHeight = Nodes.bgImage.height;
      }

      // Draw to background canvas
      Nodes.bgContext = Nodes.bgCanvas.getContext('2d');
      Nodes.bgContext.drawImage(Nodes.bgImage, (Nodes.canvas.width - newWidth) / 2, (Nodes.canvas.height - newHeight) / 2, newWidth, newHeight);
      Nodes.bgContextPixelData = Nodes.bgContext.getImageData(0, 0, Nodes.bgCanvas.width, Nodes.bgCanvas.height);

      preparePoints();
      draw();
    }

    function mouseDown(event) {
      Nodes.mouse.down = true;
    }

    function mouseUp(event) {
      Nodes.mouse.down = false;
    }

    function mouseMove(event) {
      Nodes.mouse.x = event.offsetX || (event.layerX - Nodes.canvas.offsetLeft);
      Nodes.mouse.y = event.offsetY || (event.layerY - Nodes.canvas.offsetTop);
    }

    function mouseOut(event) {
      Nodes.mouse.x = -1000;
      Nodes.mouse.y = -1000;
      Nodes.mouse.down = false;
    }

    // Resize and redraw the canvas.
    function onWindowResize() {
      cancelAnimationFrame(Nodes.animation);
      drawImageToBackground();
    }

    init();
  }, [])


  return (
    <canvas id='canvas'></canvas>
  )
}
