import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout/"
import Seo from "../components/seo"
import InteractiveNodes from "../components/interactiveNodes"
import CarouselViewer from "../components/carouselViewer";
import { header } from "../styles/about.module.css"

const AboutPage = () => {

  return (
    <Layout>
      <Container fluid className="text-white">
        <Row className="min-vh-100">
          <Col lg={{ span: 5, offset: 1, order: 0 }} xs={{ span: 8, offset: 2, order: 1 }} className="align-self-center">
            <h1 className={header}>
              Avid <span className="text-nowrap">Learner |</span>
            </h1>
            <h1 className={header}>
              Analytical <span className="text-nowrap">Mindset |</span>
            </h1>
            <h1 className={header}>
              Relationship <span className="text-nowrap">Builder |</span>
            </h1>
            <h1 className={header}>
              <span className="text-nowrap">Achiever |</span>
            </h1>
            <h1 className={header}>
              Inspired by the future and what could <span className="text-nowrap">be |</span>
            </h1>
          </Col>
          <Col lg={{ span: 6, offset: 0, order: 1 }} xs={{ span: 6, offset: 3, order: 0 }} className="align-self-center overflow-hidden">
            <InteractiveNodes />
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 10, offset: 1 }} xs={{ span: 10, offset: 2 }} sm={{ span: 9, offset: 2 }} className="align-self-center lead text-justify">
            <h2>Hi, I'm Cory...</h2>
            <p>
              Above are my top 5 strengths and what I utilize day in and day out to deliver
              outstanding results for my clients my own personal goals. I'm a seasoned leader
              with the spirit of an entrepreneur and strongly believe in providing only the best
              for the people I work with. I spend my days honing and perfecting my skills of
              programming and am always looking for new and exciting ways to resolve every day issues.
            </p>
            <p>
              My prior experience involves many different areas, all of which I use to view issues
              from multiple viewpoints and bring unique solutions to the table. I'm a Chemical Engineer
              from Kettering University. I'm an experienced Quality Systems Manager with a demonstrated
              history of solving issues in the plastics industry. I know several different problem solving
              and change management methodologies, which I use towards solving issues through programming in a quick
              and non-invasive way to a company's culture.
              I am well versed in Java, JavaScript, HTML, CSS, React, Bootstrap, Express, Node.js, SQL, NoSQL,
              Microsoft Office, SharePoint, and SAP. I have strong leadership abilities, great written and
              verbal communication skills, and an excellent work ethic with a strong capacity for learning.
            </p>
            <p>
              My favorite place to be is with a diverse group of individuals solving some of
              the biggest issues of today and our future, improving other's quality of life in the process
            </p>
            <Row>
              <Col lg={6}>
                <p>
                  <strong>What I Love:</strong>
                </p>
                <ul>
                  <li>A great book on a rainy day</li>
                  <li>Being outdoors, traveling, and exploring life</li>
                  <li>My friends and family</li>
                  <li>Continually challenging myself and learning new things</li>
                  <li>Developing and deploying a simplistic program to help others in their life or work</li>
                  <li>Cooking, trying new cuisines, and improving upon my recipes</li>
                  <li>Photography and capturing the beauty of everyday life</li>
                  <li>Movies and the creative insight of others</li>
                  <li>The process of making a great cup of coffee and the science behind it</li>
                </ul>
              </Col>
              <Col lg={6}>
                <p>
                  <strong>Weaknesses (Otherwise known as my strengths that need work):</strong>
                </p>
                <ul>
                  <li>Sensing the emotions of others around me and sharing those feelings as if they are my own</li>
                  <li>Identifying when a solution is good enough, instead of seeking perfection</li>
                  <li>Knowing everything there is to know about Computer Science and the numerous languages currently in use</li>
                  <li>Living in the moment instead of always looking towards the future</li>
                </ul>
              </Col>
            </Row>
            <p>
              I've attached my one-page resume below if you would like to know more
              about my life experiences, skills, education, and more.
            </p>
            {/* <p>
              I've attached my one-page resume below, as well as a lengthier CV, if you would like to know more
              about my life experiences, skills, education, and more.
            </p> */}
            <p>
              <a href="../../resume.pdf" target="_blank" rel="noopener noreferrer">My Resume</a>{' '}
              <sup>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 32 32">
                  <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                  <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                </svg>
              </sup>
              {/* <br />
              <a href="/" target="_blank" rel="noopener noreferrer">My Curriculum Vitae</a>{' '}
              <sup>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 32 32">
                  <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
                  <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
                </svg>
              </sup> */}
            </p>
            <p>
              I hope you enjoyed learning a little more about me and my journey.  If not,
              the least I can do is provide some beautiful images of nature for your time.  Thank you.
            </p>
          </Col>
        </Row>
      </Container>
      <CarouselViewer />
    </Layout>
  );
}

export const Head = (props) => {
  const pageSeo = {
    title: "About",
    pathname: props.location.pathname,
  }

  return (
    <Seo pageSeo={pageSeo} />
  )
}

export default AboutPage